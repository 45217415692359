module controllers {
    export module tariff {
        interface IRestrictionOptOutScope extends ng.IScope {
        }
        interface IRestrictionOptOutParams extends ng.ui.IStateParamsService {
        }

        export class restrictionOptOutCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', "$stateParams",
                "restrictionOptOutService", "entityService","shipperService",'consigneeService','transportModeService',"unLocationCodeService", 'incotermService',
                "termsOfDeliveryService",
                'companyService',   'prohibitedAndRestrictedService'
            ];

            restrictionOptOutList: Array<interfaces.tariff.IRestrictionOptOut> = [];
            apiRestrictionOptOutList: uiGrid.IGridApi;
            currentEntity: interfaces.applicationcore.IDropdownModel;
            entity: interfaces.applicationcore.IDropdownModel;
            entityId: number = undefined;
            IsLoading: boolean = true;
            showAll: boolean = true;
            IsSetRowInactive: boolean = false;

            curentEntityId:number;

            constructor(private $scope: IRestrictionOptOutScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: IRestrictionOptOutParams,
                private RestrictionOptOutService: interfaces.tariff.IRestrictionOptOutService,
                private entityService: interfaces.applicationcore.IEntityService,
                private shipperService: interfaces.master.IShipperService,
                public consigneeService: services.master.consigneeService,
                public transportModeService: services.applicationcore.transportModeService,
                public unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService,
                public incotermService: services.applicationcore.incotermService,
                public termsOfDeliveryService: interfaces.master.ITermsOfDeliveryService,
                public companyService: services.master.companyService,
                private prohibitedAndRestrictedService: interfaces.tariff.IProhibitedAndRestrictedService,
            ) {
                this.getCurrentEntity();
                this.loadRestrictionOptOuts();
            }

            loadRestrictionOptOuts() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'restriction.optout'
                }, () => {
                    return this.RestrictionOptOutService.getList().query({
                    }, (result: Array<interfaces.tariff.IRestrictionOptOut>) => {
                        this.restrictionOptOutList = result;                       
                        this.$timeout(() => {
                            this.gvwRestrictionOptOutList.data = result;
                        });
                          this.IsLoading = false;
                    }, (errorResponse) => {
                          this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                          this.IsLoading = false;
                    }).$promise;
                });
            }

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.curentEntityId = result.Id;
                    this.currentEntity  =  <interfaces.applicationcore.IDropdownModel> {Id: result.Id,Code: result.Code,Description: result.Name,Display:result.Code +  ' - ' + result.Name};
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }


            loadCompanies(searchText: string, selectedEntity:number) {
                return this.companyService.getCompanyDropdownList(selectedEntity, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            loadRestrictions(searchText:string, countryId:number){
                return this.prohibitedAndRestrictedService.getForDropDown(searchText,countryId).query(()=>{
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            loadCheckRestrictionCountries(searchText, entityId:number){
                return this.prohibitedAndRestrictedService.getCheckRestrictionCountriesForDropDown(searchText,entityId).query(()=>{
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            addNewRestrictionOptOut() {
                var add = <interfaces.tariff.IRestrictionOptOut>{
                    Entity : this.currentEntity,
                    IsActive: true,
                };
                (<Array<any>>(this.gvwRestrictionOptOutList.data)).splice(0, 0, add);
            };

            removeInlineRestrictionOptOut(entity: interfaces.tariff.IRestrictionOptOut) {
                if (entity.Id > 0)
                {
                    entity.IsActive = false;
                    this.apiRestrictionOptOutList.rowEdit.setRowsDirty([entity]);
                    this.IsSetRowInactive = true;
                    this.submitRestrictionOptOuts()
                }
                else
                {
                    _.remove(this.gvwRestrictionOptOutList.data, (o) => {
                        return o === entity;
                    });
                }
            }

            registerRestrictionOptOutGridApi(gridApi: uiGrid.IGridApi) {
                this.apiRestrictionOptOutList = gridApi;

                this.apiRestrictionOptOutList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiRestrictionOptOutList.selection.selectRow(gridSelection.row.entity);
                });

                this.$timeout(() => {
                    this.gvwRestrictionOptOutList.data = this.restrictionOptOutList;
                });
            };

            companyChanged(model: interfaces.applicationcore.IDropdownModel, row: uiGrid.IGridRow) {
                if (!model) {
                    //row.entity.CostModelElement = undefined;
                }else
                {

                }
            }


            gvwRestrictionOptOutList: uiGrid.IGridOptions | any = {
                data: <Array<interfaces.tariff.IRestrictionOptOut>>[],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerRestrictionOptOutGridApi(gridApi); },
                gridMenuShowHideColumns: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: 'RestrictionOptOutList.xlsx',
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD', 'NUMRECS'],
                columnDefs: [ 
                    {
                        name: "ADD",
                        displayName: "",
                        field: "",
                        
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEditOnFocus: false,
                        width: 55,
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.restrictionOptOutCtrl.removeInlineRestrictionOptOut(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,                        
                        headerCellTemplate: `
                            <div class="GridButton">
                                <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.restrictionOptOutCtrl.addNewRestrictionOptOut()">
                                    <span class="fa fa-plus"></span>
                                </button>
                            </div>`
                    },
                    {
                        name: "ENTITY",
                        displayName: "* Entity",
                        field: "Entity",
                        enableCellEditOnFocus: true,
                        enableCellEdit: true,
                        width: 120,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm"
                                        ng-change="grid.appScope.restrictionOptOutCtrl.makeRowDirty(row)"
                                        load-data="grid.appScope.restrictionOptOutCtrl.loadEntities(searchText)" pagination-search="true" refresh-delay="1000"                                       
                                        ></gts-dropdown>
                                    </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
    
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                                return null;
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiRestrictionOptOutList.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }
    
                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }
    
                                return 0;
                            }
                        },
                    },
                    {
                        name: "Company",
                        displayName: "Company",
                        field: "Company",
                        enableCellEditOnFocus: true,
                        enableCellEdit: true,
                        width: 200,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.restrictionOptOutCtrl.loadCompanies(searchText,row.entity.Entity.Id)" pagination-search="true" refresh-delay="1000"
                                        ng-change="grid.appScope.restrictionOptOutCtrl.makeRowDirty(row)" 
                                        ></gts-dropdown>
                                    </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
    
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                                return null;
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiRestrictionOptOutList.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }
    
                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }
    
                                return 0;
                            }
                        },
                    },
                    {
                        name: "Country",
                        displayName: "* Country",
                        field: "Country",
                        enableCellEditOnFocus: true,
                        enableCellEdit: true,
                        width: 200,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.restrictionOptOutCtrl.loadCheckRestrictionCountries(searchText,row.entity.Entity.Id)" pagination-search="true" refresh-delay="1000"
                                        ng-change="grid.appScope.restrictionOptOutCtrl.makeRowDirty(row)" 
                                        ></gts-dropdown>
                                    </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
    
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                                return null;
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiRestrictionOptOutList.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }
    
                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }
    
                                return 0;
                            }
                        },
                    },                    
                    {
                        name: "Restriction",
                        displayName: "* Restriction",
                        field: "Restriction",
                        enableCellEditOnFocus: true,
                        enableCellEdit: true,
                        width: 340,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.restrictionOptOutCtrl.loadRestrictions(searchText,row.entity.Country.Id)" pagination-search="true" refresh-delay="1000"
                                        ng-change="grid.appScope.restrictionOptOutCtrl.companyChanged(model, row)"
                                        ></gts-dropdown>
                                    </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
    
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                                return null;
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiRestrictionOptOutList.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }
    
                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }
    
                                return 0;
                            }
                        },
                    }                    
                    ,
                    {
                        name: "COMMENTS",
                        displayName: "Comments",
                        field: "Comments",
                        width: 175,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                        type: "string",
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                            <form name = "inputForm">
                                <p class="input-group-sm">
                                    <textarea  rows="3" type="text" name="Description-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" class="form-control" ui-grid-editor ></textarea>
                                </p>
                            </form>`,
                    },
                {
                    name: "ACTIVE",
                    displayName: "* Active",
                    field: "IsActive",
                    width: 70,
                    cellTemplate: `<center><input type="checkbox" ng-change="grid.appScope.restrictionOptOutCtrl.makeRowDirty(row)" ng-model="row.entity.IsActive"></center>`,
                    editableCellTemplate: ` 
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="row.entity.IsActive">
                                </p>
                            </form>`,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                }, {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                            </gts-stamp-info>
                        </div>`,
                    width: 45,
                    enableSorting: false,
                },]
            };

            makeRowDirty(row: uiGrid.IGridRow) {
                this.apiRestrictionOptOutList.rowEdit.setRowsDirty([row.entity]);
            }

            entityChange(model: interfaces.applicationcore.IDropdownModel) {
                this.entity = model;

                if (model)
                    this.entityId = model.Id;
                else
                    this.entityId = undefined;
            }
                      
            
            //Submit
            submitRestrictionOptOuts() {
                //Get dirty products.
                var restrictionOptOutList: Array<interfaces.tariff.IRestrictionOptOut> = [];
                if (this.apiRestrictionOptOutList && this.apiRestrictionOptOutList.rowEdit.getDirtyRows().length > 0) {
                    restrictionOptOutList = _.map(this.apiRestrictionOptOutList.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'restriction.optout'
                }, () => {
                    //Save Dirty Products
                    return this.RestrictionOptOutService.SaveRestrictionOptOuts().save(restrictionOptOutList, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        this.$timeout(() => {
                            if (this.apiRestrictionOptOutList && !data.HasErrorMessage) {
                                this.apiRestrictionOptOutList.rowEdit.setRowsClean(restrictionOptOutList);
                                this.loadRestrictionOptOuts();

                                if (this.IsSetRowInactive){
                                    this.generalService.displayMessage("Restriction Opt Out set to inactive.", Enum.EnumMessageType.Success);
                                    this.IsSetRowInactive = false;
                                }                            
                            }
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            };

            Close() {
                this.entity = undefined;
                this.entityId = undefined;
                this.$anchorScroll("topAnchor");
                this.$state.go("^");
            }
        }

        angular.module("app").controller("restrictionOptOutCtrl", controllers.tariff.restrictionOptOutCtrl);
    }
}